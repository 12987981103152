import { MouseEvent, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  alpha,
  Avatar,
  CircularProgress,
  IconButton,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  NotificationsItemData,
  NotificationsListData,
  NotificationTypeEnum,
} from 'types/Notifications';
import { getUserInitials } from 'helpers/common';
import { getFormattedDateFromNow } from 'utils/dayJsFormats';
import { appLinks } from 'routes/routes';
import commentsActions from 'store/actions/commentsActions';
import { ProjectSettingsTabsEnum } from 'types/common';
import { useApiRequest, useStore } from 'hooks';
import notificationsApi from 'api/notificationsApi';
import { HIGHLIGHT_TIMER_MS } from 'constants/common';
import { CommentsItemStatusEnum } from 'types/Comments';
import { KeyedMutator } from 'swr/dist/types';
import EllipsisText from '../EllipsisText';

interface Props {
  data: NotificationsItemData;
  showProjectTitle?: boolean;
  projectId?: string;
  onClose: () => void;
  onOpenProjectSettings: (activeTab: ProjectSettingsTabsEnum) => void;
  mutate: KeyedMutator<NotificationsListData>;
}

const NotificationsItem = (props: Props): JSX.Element | null => {
  const { data, showProjectTitle, projectId, onClose, onOpenProjectSettings, mutate } = props;
  const history = useHistory();
  const { dispatch } = useStore();
  const { requestFn, isLoading } = useApiRequest(
    data.readAt ? notificationsApi.markAsUnread : notificationsApi.markAsRead
  );

  const handleReadStateChange = useCallback(
    async (event?: MouseEvent<HTMLButtonElement>) => {
      event?.preventDefault();
      event?.stopPropagation();

      await requestFn({
        args: { ids: [data._id] },
      });

      await mutate();
    },
    [data._id, mutate, requestFn]
  );

  const handleSettingClick = useCallback(
    (activeTab: ProjectSettingsTabsEnum) => {
      if (!projectId) {
        history.push(appLinks.projectDetails.as(data.project._id));
      }

      onOpenProjectSettings(activeTab);
    },
    [projectId, onOpenProjectSettings, history, data.project._id]
  );

  const handleCommentClick = useCallback(async () => {
    if (!projectId) {
      history.push(appLinks.projectDetails.as(data.project._id));
    }

    if (!data.comment) return;

    if (data.comment.status !== CommentsItemStatusEnum.DELETED) {
      dispatch(commentsActions.setPathnameFilter(data.comment.pathname));
      dispatch(commentsActions.setDeviceTypeFilter(data.comment.deviceType));
      dispatch(commentsActions.setStatusTypeFilter(data.comment.status));
    }

    if (data.comment && data.comment.status !== CommentsItemStatusEnum.DELETED) {
      dispatch(commentsActions.setActiveCommentId(data.comment._id));

      setTimeout(() => {
        dispatch(commentsActions.setActiveCommentId(undefined));
      }, HIGHLIGHT_TIMER_MS);
    }

    onClose();
  }, [data, dispatch, history, onClose, projectId]);

  const handleNotificationClick = useCallback(
    async (event: MouseEvent<HTMLLIElement>) => {
      event.preventDefault();
      event.stopPropagation();

      if (data.readAt === null) {
        await handleReadStateChange();
      }

      switch (data.notificationType) {
        case NotificationTypeEnum.NEW_MEMBER_IN_BROWSER:
        case NotificationTypeEnum.NEW_MEMBER_IN_APP:
        case NotificationTypeEnum.NEW_MEMBER_BY_EMAIL:
          handleSettingClick(ProjectSettingsTabsEnum.MEMBERS);
          break;
        case NotificationTypeEnum.NEW_INTEGRATION_IN_APP:
          handleSettingClick(ProjectSettingsTabsEnum.INTEGRATIONS);
          break;
        default:
          await handleCommentClick();
          break;
      }
    },
    [
      data.notificationType,
      data.readAt,
      handleCommentClick,
      handleReadStateChange,
      handleSettingClick,
    ]
  );

  const notification = useMemo(() => {
    switch (data.notificationType) {
      case NotificationTypeEnum.NEW_COMMENT_BY_EMAIL:
      case NotificationTypeEnum.NEW_COMMENT_IN_APP:
      case NotificationTypeEnum.NEW_COMMENT_IN_BROWSER:
        return {
          commentNumber: data.comment?.orderNumber || 0,
          path: data.comment?.pathname || 'unknown',

          title: `${data.sender?.username || 'unknown'} commented`,
          body: data.comment?.body || 'unknown',
          date: data.createdAt,
          avatar: data.sender?.avatar?.mediaUrl || '',
          username: data.sender?.username || 'unknown',
          projectTitle: data.project.title,
        };
      case NotificationTypeEnum.NEW_MENTION_BY_EMAIL:
      case NotificationTypeEnum.NEW_MENTION_IN_APP:
      case NotificationTypeEnum.NEW_MENTION_IN_BROWSER:
        return {
          commentNumber: data.comment?.orderNumber || 0,
          path: data.comment?.pathname || 'unknown',

          title: `${data.sender?.username} mentioned you`,
          body: data.comment?.body || 'unknown',
          date: data.createdAt,
          avatar: data.sender?.avatar?.mediaUrl || '',
          username: data.sender?.username || 'unknown',
          projectTitle: data.project.title,
        };
      case NotificationTypeEnum.NEW_REPLY_BY_EMAIL:
      case NotificationTypeEnum.NEW_REPLY_IN_APP:
      case NotificationTypeEnum.NEW_REPLY_IN_BROWSER:
        return {
          commentNumber: data.comment?.orderNumber || 0,
          path: data.comment?.pathname || 'unknown',

          title: `${
            data.sender?.username || data.publicCommenter
              ? `${data.publicCommenter?.firstName || 'unknown'} ${
                  data.publicCommenter?.lastName || 'unknown'
                }`
              : 'unknown'
          } replied`,
          body: data.replyComment?.body || 'unknown',
          date: data.createdAt,
          avatar: data.sender?.avatar?.mediaUrl || '',
          username: data.sender?.username || 'unknown',
          projectTitle: data.project.title,
        };
      case NotificationTypeEnum.RESOLVED_COMMENT_BY_COMMENT_EMAIL:
      case NotificationTypeEnum.RESOLVED_COMMENT_IN_APP:
      case NotificationTypeEnum.RESOLVED_COMMENT_IN_BROWSER:
        return {
          commentNumber: data.comment?.orderNumber || 0,
          path: data.comment?.pathname || 'unknown',

          title: `${data.sender?.username || 'unknown'} resolved`,
          body: data.comment?.body,
          date: data.createdAt,
          avatar: data.sender?.avatar?.mediaUrl || '',
          username: data.sender?.username || 'unknown',
          projectTitle: data.project.title,
        };
      case NotificationTypeEnum.NEW_MEMBER_BY_EMAIL:
      case NotificationTypeEnum.NEW_MEMBER_IN_APP:
      case NotificationTypeEnum.NEW_MEMBER_IN_BROWSER:
        return {
          title: `${data.sender?.username || 'unknown'} joined`,
          body: `${data.sender?.username || 'unknown'} joined ${
            data.project.domain
          } as collaborator`,
          date: data.createdAt,
          avatar: data.sender?.avatar?.mediaUrl || '',
          username: data.sender?.username || 'unknown',
          projectTitle: data.project.title,
        };
      case NotificationTypeEnum.NEW_INTEGRATION_IN_APP:
        return {
          title: `${data.sender?.username || 'unknown'} added ${data.integrationName}`,
          body: `${data.sender?.username || 'unknown'} added ${data.integrationName} to ${
            data.project.title
          }`,
          date: data.createdAt,
          avatar: data.sender?.avatar?.mediaUrl || '',
          username: data.sender?.username || 'unknown',
          projectTitle: data.project.title,
        };
      default:
        return undefined;
    }
  }, [data]);

  if (!notification) {
    return null;
  }

  return (
    <MenuItem
      onClick={handleNotificationClick}
      sx={(theme) => ({
        alignItems: 'flex-start',
        p: 1.5,
        bgcolor: data.readAt === null ? alpha(theme.palette.primary.main, 0.1) : undefined,
        '&:hover': {
          bgcolor: data.readAt === null ? alpha(theme.palette.primary.main, 0.2) : undefined,
        },
      })}
    >
      <ListItemAvatar
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          [`& > div`]: {
            width: '24px',
            height: '24px',
            border: `2px solid ${theme.palette.background.paper}`,
            boxSizing: 'content-box',
            marginBottom: -1,
            fontSize: '12px',
            fontWeight: 600,
            '&:last-child': {
              marginBottom: 0,
            },
          },
        })}
      >
        <Avatar
          src={notification.avatar}
          sx={(theme) => ({
            bgcolor: theme.palette.primary.main,
          })}
        >
          {getUserInitials(notification.username)}
        </Avatar>
        {notification.commentNumber && (
          <Avatar
            sx={(theme) => ({
              bgcolor: theme.palette.primary.light,
            })}
          >
            {notification.commentNumber}
          </Avatar>
        )}
      </ListItemAvatar>
      <ListItemText
        disableTypography
        sx={{ pr: 3, whiteSpace: 'break-spaces', wordBreak: 'break-word', my: 0 }}
      >
        {showProjectTitle && (
          <Typography variant="caption" display="block" color="text.secondary">
            {notification.projectTitle}
          </Typography>
        )}
        {notification.path && (
          <Typography variant="caption" display="block" color="text.secondary">
            <EllipsisText>
              {notification.path === '/' ? 'Home page' : notification.path}
            </EllipsisText>
          </Typography>
        )}
        {data.comment?.status === CommentsItemStatusEnum.DELETED && (
          <Typography variant="caption" display="block" color="error">
            Comment deleted
          </Typography>
        )}
        <Typography color="text.primary" fontWeight={600}>
          {notification.title}
        </Typography>
        <Typography color="text.primary">{notification.body}</Typography>
        <Typography variant="caption" display="block" color="text.secondary">
          {getFormattedDateFromNow(notification.date)}
        </Typography>
      </ListItemText>
      <IconButton
        color="primary"
        onClick={handleReadStateChange}
        sx={(theme) => ({
          mt: 1,
          width: '5px',
          height: '5px',
          padding: '6px',
          bgcolor: data.readAt === null ? theme.palette.primary.main : theme.palette.action.hover,
          '&:hover': {
            bgcolor:
              data.readAt === null ? theme.palette.primary.dark : theme.palette.action.active,
          },
        })}
      >
        {isLoading && (
          <CircularProgress
            size={14}
            thickness={6}
            sx={(theme) => ({
              position: 'absolute',
              top: '-1px',
              left: '-1px',
              zIndex: 'inherit',
              color: data.readAt === null ? theme.palette.common.white : theme.palette.primary.main,
            })}
          />
        )}
      </IconButton>
    </MenuItem>
  );
};

export default NotificationsItem;
